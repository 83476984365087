<script setup lang="ts">
import { transformMany } from '~/utils/transform'
import type { City } from "~/types/city";

const { find } = useStrapi()

const cities:any = ref([])

const fetchData = async () => {
  const { data: residence } = await useAsyncData(() => find<City>('cities', {
    filters: {
        showOnFrontPage: {
          $eq: true
        }
    },
    sort: 'name',
    pagination: {
        start: 0,
        limit: 10
    }
  }), { transform: transformMany<City> })

  cities.value = residence.value?.data
}

fetchData()
</script>

<template>
  
  <div class="p-4 lg:py-40 lg:pb-20 custom-bg">
    <CategoryBox /> 

    <div class="flex items-center justify-center flex-col">
      
      <InputSearch class="w-full md:w-[600px] p-2 mb-2" />
      
      <div class="flex">
        <div class="max-w-3xl text-center flex flex-wrap gap-1">
          <AppLink v-for="(city, index) in cities" :key="index" :to="'/search?cities=' + city.id + '&start=0'" variant="secondary-1" class="px-2 py-1 text-sm drop-shadow rounded-md">
            {{ city.name }}
          </AppLink>
        </div>
      </div>

      <div class="mt-5 lg:mt-20 text-md lg:text-xl font-bold text-center mb-5 text-white-10 drop-shadow">
        <NuxtLink to="/products">
          Er du sælger? Klik her for at læse mere
        </NuxtLink>
      </div>

    </div>
  </div>
  
</template>
<style>
  .custom-bg {
    background: rgb(174,219,151);
    background: linear-gradient(225deg, rgba(174,219,151,1) 0%, rgba(127,163,117,1) 45%, rgba(111,154,110,1) 100%);
  }
</style>
