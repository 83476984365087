<script lang="ts" setup>
const categories = useHousing()
</script>
  
<template>
    <div class="w-full md:w-[600px] mx-auto pb-5">
        <div class="text-2xl lg:text-6xl font-bold text-center mb-5 text-white-10 drop-shadow">
            Find din næste bolig her
        </div>
        <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0 justify-center">
            <AppLink v-for="category in categories" :key="category.id" :to="'/search?types=' + category.name + '&start=0'" class="px-3 py-2">
                {{ category.text }}
            </AppLink>
        </div>
    </div>
</template>
